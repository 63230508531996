import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Image from "../../composants/image/Image"
import LayoutTutorielFR from "../../composants/layout/fr/LayoutTutorielFR"
import Lien from "../../composants/navigation/Lien"
import Section from "../../composants/layout/Section"

import {
  obtenirImage,
  obtenirImages,
  obtenirPage,
  obtenirCartesLiensVersAutresPages,
} from "../../js/client-es/utils"
import fragmentsImages from "../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageAstrophotographiePhotoshop: allPagesCompilationYaml(
      filter: { code: { eq: "astrophotographiePhotoshop" } }
    ) {
      ...FragmentPageYaml
    }
    pagesPourAllerPlusLoin: allPagesCompilationYaml(
      filter: {
        code: {
          in: [
            "megalaxyZoom"
            "paletteHubblePhotoshop"
            "galerieNebuleusesCouleursNaturelles"
          ]
        }
      }
      sort: { fields: ordre_carte, order: ASC }
    ) {
      ...FragmentCarteLienVersPage
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "logicielsLesmasquesDeFusionDansPhotoshop"
            "logicielsLaselectionDesEtoilesALaideDeLoutilSeuil"
            "logicielsReductiondeBruitSelectiveDansPhotoshop"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }
    imagesPaysageMoyen: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "logicielsLadeconvolutionDansPhotoshop"
            "logicielsReductionduBruitSurUneSeuleCoucheCouleur"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageMoyen
    }
    imagesPaysagePetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "logicielsSaturationdesCouleursALaideDeLoutilTonsFonces-TonsClairsDePhotoshop"
          ]
        }
      }
    ) {
      ...FragmentImagePaysagePetit
    }
    imagesPaysageTresPetit: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "logicielsSaturationdesCouleursALaideDeLaMethodeLAB"
            "logicielsNiveauxcoucheVerte"
            "logicielsNiveauxcoucheRouge"
            "logicielsNiveauxcoucheBleue"
          ]
        }
      }
    ) {
      ...FragmentImagePaysageTresPetit
    }
    imagesPaysageMicro: allImagesCompilationYaml(
      filter: {
        code: { in: ["logicielsUnfilRelieLesEtoilesEnCasDeReductionExcessive"] }
      }
    ) {
      ...FragmentImagePaysageMicro
    }
  }
`
export default function AstrophotographiePhotoshop() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageAstrophotographiePhotoshop")
  const pagesPourAllerPlusLoin = obtenirCartesLiensVersAutresPages(
    resultatsRequete,
    "pagesPourAllerPlusLoin"
  )
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPaysageMoyen",
    "imagesPaysagePetit",
    "imagesPaysageTresPetit",
    "imagesPaysageMicro"
  )

  return (
    <LayoutTutorielFR
      page={page}
      pagesPourAllerPlusLoin={pagesPourAllerPlusLoin}
    >
      <Section titre="Introduction">
        <p>
          Voici une compilation de quelques techniques de traitement des
          astrophotographies sous Photoshop. Ce sont celles que j’utilise le
          plus. Elles sont le fruit de nombreuses recherches sur Internet mais
          surtout de longues années de pratique de l’astrophotographie
          numérique. Employez-les à bon escient !
        </p>
      </Section>
      <Section titre="Règles générales">
        <p>Retenez les quelques règles suivantes…</p>
        <ul>
          <li>
            Les traitements <strong>les plus simples</strong> sont souvent{" "}
            <strong>les meilleurs</strong>.
          </li>
          <li>
            Au cours de votre traitement, consultez régulièrement l’image{" "}
            <strong>à 100%</strong> pour vérifier que vous n’avez pas provoqué
            de dégâts : augmentation du bruit, étoiles dégradées…
          </li>
          <li>
            Réalisez votre traitement dans <strong>une pièce sombre</strong>{" "}
            pour bien distinguer tous les défauts de votre image.
          </li>
          <li>
            Une fois que vous pensez avoir traité votre image,{" "}
            <strong>ne la montrez pas tout de suite</strong>. Revenez-y quelques
            heures ou quelques jours après. Vous lui trouverez sans doutes de
            nouveaux défauts : trop sombre, trop rouge, trop saturée, etc.
          </li>
          <li>
            <strong>Consultez très régulièrement l’histogramme</strong> de votre
            image en allant dans « Image &gt; Réglages &gt; Niveaux » et
            vérifiez que vos couleurs sont bien équilibrées (pas de dominante de
            couleur). S’il y a une dominante, corrigez-la immédiatement.
          </li>
          <li>
            N’appliquez les filtres de réduction d’étoiles et de renforcement de
            détails qu’en <strong>toute fin de traitement</strong>.
          </li>
        </ul>
      </Section>
      <Section titre="Réduction du bruit">
        <h3>Rappel sur les masques de fusion</h3>
        <p>
          Dans Photoshop, il est possible de créer des masques de fusion pour
          tous les calques présents sur l’image. Un masque de fusion entièrement{" "}
          <strong>noir masque entièrement le calque</strong>, un masque de
          fusion <strong>blanc laisse entièrement apparaître le calque</strong>.
        </p>
        <Image
          image={obtenirImage(
            images,
            "logicielsLesmasquesDeFusionDansPhotoshop"
          )}
          langue={page.langue}
        />
        <p>
          Vous pourrez ensuite « peindre » le masque de fusion de noir, de gris,
          de blanc… en fonction de vos besoins. Pour afficher un masque de
          fusion, sélectionnez le calque qui lui est lié dans la palette des
          calques, puis utilisez le raccourci « Alt + clic sur le masque de
          fusion ».
        </p>
        <h3>Réduction de bruit sélective</h3>
        <p>
          Vous connaissez certainement <strong>les effets destructeurs</strong>{" "}
          de la <strong>réduction de bruit</strong>. Il serait donc idéal de
          pouvoir réduire le bruit dans les zones sombres d’une
          astrophotographie ne contenant pas détails (le fond de ciel), et de ne
          rien faire dans les zones lumineuses contenant de nombreux détails.
        </p>
        <p>
          À l’aide des masques de fusion, il est très facile de réaliser cette
          manipulation dans Photoshop :
        </p>
        <ul>
          <li>
            1. Sélectionnez le calque pour lequel vous souhaitez réduire le
            bruit.
          </li>
          <li>
            2. Sélectionnez entièrement l’image qui s’y trouve (Ctrl + a), puis
            copiez-la (Ctrl + c).
          </li>
          <li>3. Dupliquez le calque d’arrière-plan : Ctrl + j.</li>
          <li>
            4. Réduisez le bruit de ce calque dupliqué avec votre outil favori.
          </li>
          <li>5. Sur le calque dupliqué, créez un masque de fusion.</li>
          <li>
            6. Affichez le masque de fusion : Alt + clic sur le masque de
            fusion.
          </li>
          <li>
            7. Dans le masque de fusion, collez l’image que vous avez copiée en
            2 (Ctrl + v).
          </li>
          <li>
            8. Invertissez les couleurs du calque copié : raccourci Ctrl + i.
          </li>
          <li>
            9. Réglez les niveaux du masque de fusion pour cibler la réduction
            du bruit.
          </li>
        </ul>
        <Image
          image={obtenirImage(
            images,
            "logicielsReductiondeBruitSelectiveDansPhotoshop"
          )}
          langue={page.langue}
        />
        <h3>L’outil de réduction de bruit de Photoshop</h3>
        <p>
          Deux mots sur l’outil de réduction du bruit dans Photoshop (dans «
          Filtres &gt; Bruit &gt; Réduction du bruit ») :
        </p>
        <ul>
          <li>
            Faites <strong>très attention</strong> de ne pas trop pousser le
            curseur de réduction du bruit de la couleur, sous peine de perdre
            toutes les couleurs de vos étoiles.
          </li>
          <li>
            Cet outil vous permet de cibler la couche sur laquelle vous
            appliquerez la réduction du bruit. Si seule la couche bleue est
            bruitée, il est inutile d’appliquer une réduction de bruit sur les
            autres couches. Pour cela, affichez les options avancées de l’outil,
            puis sélectionnez l’onglet « Par couche ». Vous aurez accès à des
            fonctionnalités de réduction de bruit par couche de couleur.
          </li>
        </ul>

        <Image
          image={obtenirImage(
            images,
            "logicielsReductionduBruitSurUneSeuleCoucheCouleur"
          )}
          langue={page.langue}
        />
      </Section>
      <Section titre="Fond de ciel">
        <p>
          Une technique très efficace pour{" "}
          <strong>homogénéiser le fond de ciel</strong> d’une astrophotographie
          : trop bruité ou ayant des zones plus sombres que d’autres. Nous
          allons créer un fond de ciel synthétique que nous collerons en mode «
          éclaircir » sur l’image finale :
        </p>
        <ul>
          <li>
            1. À l’aide de la pipette, prélevez la couleur d’un morceau de fond
            de ciel que vous souhaitez appliquer sur toute votre image (dans la
            configuration de l’outil, choisissez une moyenne de 5×5 par
            exemple).
          </li>
          <li>
            2. Copiez votre image entière : Ctrl + A, puis Ctrl + C. Dans la
            manipulation suivante, cela permettra de créer une image directement
            aux bonnes dimensions.
          </li>
          <li>
            3. Créez une nouvelle image. À l’aide du pot de peinture,
            remplissez-la avec la valeur prélevée avec la pipette précédemment.
          </li>
          <li>
            4. Ajoutez du bruit pour obtenir un rendu « naturel » : Filtre &gt;
            Bruit &gt; Ajout de bruit. Choisissez la quantité de bruit qui vous
            convient (2 ou 3% par exemple).
          </li>
          <li>
            5. Copiez maintenant votre fond de ciel synthétique : Ctrl + A puis
            Ctrl + C.
          </li>
          <li>
            6. Collez votre fond de ciel sur votre image : un nouveau calque est
            alors créé : choisissez le mode de fusion « éclaircir ».
          </li>
          <li>
            7. Dosez l’effet en jouant sur l’opacité du calque, ou sur la
            luminosité du calque. Il est important de le faire car cette
            technique peu détruire de faibles détails du fond de ciel, comme des
            galaxies ou des nuages sombres. Faites une vérification sur
            l’ensemble de votre image.
          </li>
        </ul>
      </Section>
      <Section titre="Couleurs">
        <h3>Équilibre</h3>
        <p>
          Quand vous traitez votre image, consultez{" "}
          <strong>très régulièrement</strong> son <strong>histogramme</strong> :
          vous ne devez pas avoir de couche décalée par rapport aux autres,
          synonyme de dominante colorée. Pour cela, vérifiez surtout que le «
          point zéro » de toutes les couches est identique.
        </p>
        <div className="conteneur-cote-a-cote">
          <Image
            image={obtenirImage(images, "logicielsNiveauxcoucheRouge")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "logicielsNiveauxcoucheVerte")}
            langue={page.langue}
          />
          <Image
            image={obtenirImage(images, "logicielsNiveauxcoucheBleue")}
            langue={page.langue}
          />
        </div>
        <p>
          Sur cet exemple les couches bleu et rouge sont équilibrées : leurs
          intensités lumineuses sont réparties de la même façon. Par contre la
          couche verte est décalée à droite. Jouez avec le point noir (le
          premier curseur) et/ou le point gris (curseur du milieu) pour la
          ramener au même niveau que les autres. Il peut être nécessaire de
          réaliser plusieurs passes pour parvenir au résultat voulu.
        </p>
        <p>Vous pouvez aussi utiliser l’outil « courbes ».</p>
        <h3>Saturation des couleurs : la méthode Lab</h3>
        <p>
          <strong>
            L’espace Lab est une technique de codification des couleurs
          </strong>
          . L’espace de couleurs (le gamut) produit contient toutes les couleurs
          perceptibles par l’œil humain. Il est utilisé dans de multiples
          logiciels sans que vous ne vous en aperceviez. Ainsi, même si vous
          travaillez en RVB, Photoshop travaille en interne en Lab.
        </p>
        <p>
          Pour représenter une couleur, l’espace Lab{" "}
          <strong>lie une intensité lumineuse</strong> (une luminance « L »){" "}
          <strong>à une valeur chromatique</strong> (le « a » représente les
          rouges et les oranges, et le « b » représente les verts et les bleus).
        </p>
        <p>
          Nous allons donc pouvoir modifier la saturation des couleurs sans
          toucher à la luminance, en serrant les niveaux des couches « a » et «
          b » :
        </p>
        <ul>
          <li>
            Passez votre image en mode « Lab » : « Image &gt; Mode &gt; Couleurs
            Lab ».
          </li>
          <li>
            Rendez-vous dans les niveaux : « Image &gt; Réglages &gt; Niveaux ».
          </li>
          <li>Sélectionnez la couche « a ».</li>
          <li>
            Augmentez la valeur du point noir de 20 (par exemple), et diminuez
            le point blanc de la même valeur.
          </li>
          <li>Faites de même avec la couche « b ».</li>
          <li>
            Une fois les manipulations réalisées, n’oubliez pas de repasser
            votre image en RVB.
          </li>
        </ul>
        <Image
          image={obtenirImage(
            images,
            "logicielsSaturationdesCouleursALaideDeLaMethodeLAB"
          )}
          langue={page.langue}
        />
        <h3>Saturation des couleurs : tons foncés / tons clairs</h3>
        <p>
          Cette technique fonctionne <strong>très bien</strong> sur une
          astrophotographie aux <strong>couleurs délavées</strong> résultant
          d’un assemblage L-RVB ou Hα-RVB (correction d’une dominante saumon par
          exemple). Elle possède en plus l’avantage{" "}
          <strong>de ne pas faire monter le bruit de l’image</strong>.
        </p>
        <p>
          Rendez-vous dans « Image &gt; Réglages &gt; Tons foncés / tons clairs
          ». Sur la fenêtre qui apparaît, cliquez sur la case « Plus d’options »
          en bas à gauche. Jouez avec les curseurs des groupes d’options
          suivants (n’oubliez pas mettre à « 0 » le facteur de correction des
          tons foncés, qui est initialisé à 35% lors de l’ouverture de la
          fenêtre) :
        </p>
        <Image
          image={obtenirImage(
            images,
            "logicielsSaturationdesCouleursALaideDeLoutilTonsFonces-TonsClairsDePhotoshop"
          )}
          langue={page.langue}
        />
      </Section>
      <Section titre="Les étoiles">
        <h3>En général…</h3>
        <p>
          <strong>
            Les étoiles contribuent au moins à 60% à la qualité d’une image
          </strong>
          . Il faut donc les surveiller{" "}
          <strong>comme le lait sur le feu</strong> lors de votre traitement. Si
          des halos sombres sont présents, ou que vos étoiles n’ont plus de
          couleurs, c’est qu’il y a eu un problème. Repérez dans l’historique
          l’étape qui a créé le problème, et reprenez votre traitement à partir
          de là.
        </p>
        <h3>Sélection des étoiles</h3>
        <p>
          La méthode suivante fonctionne bien et est rapide à exécuter : (pensez
          à en faire un script !)
        </p>
        <ul>
          <li>Dupliquez votre image sur un nouveau calque (Ctrl+j)</li>
          <li>
            Dans « Filtres &gt; Divers &gt; Passe haut » : appliquez ce filtre
            sur un rayon de 1x.
          </li>
          <li>
            Dans « Filtres &gt; Atténuation &gt; Flou gaussien » : appliquez ce
            filtre sur un rayon de 1px.
          </li>
          <li>
            Dans « Edition &gt; Seuil », saisissez un seuil de façon à ne voir
            apparaitre que vos étoiles. La valeur 129 ou 130 fonctionne bien.
            Attention : vérifiez que des parties de l’objet que vous avez
            photographié n’apparaissent pas. Si c’est le cas, après application
            du seuil, vous devrez les peindre en noir.
          </li>
        </ul>
        <Image
          image={obtenirImage(
            images,
            "logicielsLaselectionDesEtoilesALaideDeLoutilSeuil"
          )}
          langue={page.langue}
        />
        <ul>
          <li>
            En bas à droite, au-dessus de la palette des calques, cliquez sur
            l’onglet « Couches », puis sur le bouton « Récupérer la sélection »
            (le rond en pointillés tout en bas à droite de Photoshop).
          </li>
          <li>
            Vérifiez sur votre image à 100% que vos étoiles ont bien été
            sélectionnées. Éventuellement, dilatez la sélection : « Sélection
            &gt; Modifier &gt; Dilater », saisissez la valeur qui vous convient
            (je mets 1 ou 2px).
          </li>
          <li>
            Mémorisez la sélection dans une sélection que vous nommerez «
            étoiles » : « Sélection &gt; Mémoriser la sélection ».
          </li>
        </ul>
        <h3>Réduction d’étoiles</h3>
        <p>
          J’utilise l’action de Noël Carboni « Make stars smaller » (v. la liste
          des extensions en fin d’article). Attention, la réduction d’étoiles
          possède deux inconvénients majeurs (quelle que soit la méthode
          employée) :
        </p>
        <ul>
          <li>
            <strong>Elle fait perdre énormément de détails</strong>. Pour
            contourner le problème, n’appliquez la réduction d’étoiles qu’aux
            étoiles, et non à l’image entière. Pour cela, liez l’image contenant
            les étoiles réduites à un masque de fusion ne contenant que les
            étoiles de l’image. Aidez-vous de la méthode de sélection décrite
            ci-dessus.
          </li>
          <li>
            Si vous <strong>abusez</strong> de la réduction d’étoiles, un vilain
            artefact apparaît très vite : les étoiles deviennent{" "}
            <strong>reliées par un « fil »</strong>.
          </li>
        </ul>
        <Image
          image={obtenirImage(
            images,
            "logicielsUnfilRelieLesEtoilesEnCasDeReductionExcessive"
          )}
          langue={page.langue}
        />
        <p>
          <strong>
            Si ce n’est acheter un instrument de diamètre supérieur
          </strong>
          , il n’existe pas (à ma connaissance) de méthode correcte pour réduire
          la taille des étoiles. Je m’en sers peu (une à deux passes grand
          maximum pour une image), et j’évite de l’utiliser dans les images
          contenant beaucoup d’étoiles : l’artefact décrit ci-dessus apparaît
          très vite. Vous devez donc accepter les{" "}
          <strong>défauts de vos optiques</strong> !
        </p>
        <h3>Couleurs des étoiles</h3>
        <p>
          Une autre action de Noel Carboni fonctionne très bien : « Increase
          star color ». Attention, si vos étoiles sont complètement délavées
          (elles n’ont pas de couleurs et sont toutes blanches), cette action ne
          pourra rien faire pour vous.
        </p>
        <p>
          Exécutez cette action sur un calque à part, et jouez avec la
          transparence du calque pour en doser l’effet.
        </p>
        <h3>Correction d’une déformation</h3>
        <p>
          Pour corriger un problème de suivi sur une astrophotographie, la
          technique suivante fonctionne bien :
        </p>
        <ul>
          <li>Dupliquez votre calque (Ctrl + j).</li>
          <li>Changez le mode de fusion du calque dupliqué : « Obscurcir ».</li>
          <li>
            Rendez-vous dans « Filtres &gt; Divers &gt; Translation ». Appliquez
            la translation nécessaire pour corriger la déformation de vos
            étoiles.
          </li>
          <li>
            Utilisez un masque de fusion pour ne corriger que les étoiles
            concernées.
          </li>
        </ul>
      </Section>
      <Section titre="Accentuation des détails">
        <h3>La déconvolution dans Photoshop</h3>
        <p>
          La <strong>déconvolution</strong> (consultez{" "}
          <Lien urlExterne="https://fr.wikipedia.org/wiki/D%C3%A9convolution">
            cet article
          </Lien>{" "}
          sur Wikipédia){" "}
          <strong>est un algorithme d’accentuation des détails</strong>,
          beaucoup plus performant qu’un simple filtre de renforcement car elle
          va permettre de révéler des détails dans votre image.
        </p>
        <p>
          Si cet algorithme est réservé aux logiciels de traitement d’images
          astronomiques, il en existe aussi un équivalent dans Photoshop.
          Rendez-vous dans le menu « Filtres &gt; Renforcement &gt; Netteté
          optimisée ». Dans la boîte de dialogue qui apparaît, sélectionnez «
          Flou de l’objectif » dans la liste déroulante « Supprimer ». Ce sera
          la PSF - Point Spread Function - qui sera retirée de l’image.
        </p>
        <Image
          image={obtenirImage(images, "logicielsLadeconvolutionDansPhotoshop")}
          langue={page.langue}
        />
        <p>
          Jouez avec les curseurs pour trouver le bon réglage. Bien sûr, comme
          tout algorithme de renforcement des détails, celui-ci entraîne une
          montée du bruit. L’idéal est donc de l’appliquer sur un calque
          spécifique, lié à un masque de fusion ne laissant apparaître que les
          zones à renforcer.
        </p>
        <h3>Renforcement du contraste</h3>
        <p>
          Une technique bien connue, celle du <strong>filtre passe-haut</strong>{" "}
          :
        </p>
        <ul>
          <li>1. Dupliquez votre image sur un nouveau calque : « Ctrl + j».</li>
          <li>
            2. Dans le menu « Filtres &gt; Divers &gt; Passe-haut », saisissez
            une valeur de 3px pour renforcer le contraste des petits détails, ou
            une valeur comprise entre 30 et 100 pour renforcer le contraste de
            grandes zones dans votre image. Cliquez sur « OK ».
          </li>
          <li>
            3. Le calque apparaît tout gris, c’est normal. Changez l’option de
            fusion de votre calque : choisissez le mode « Incrustation ».
          </li>
        </ul>
        <h3>Atténuation du renforcement</h3>
        <p>
          Dans tous les cas, les actions de renforcement ont des{" "}
          <strong>effets négatifs</strong> sur l’image. Ils ne sont efficaces
          que sur certaines zones de l’image,{" "}
          <strong>il faut donc cibler leur action.</strong> Quelques pistes :
        </p>
        <ul>
          <li>a. Jouez avec l’opacité des calques.</li>
          <li>
            b. Créez un masque de fusion pour ne faire apparaître le calque que
            sur des zones précises de l’image finale.
          </li>
          <li>
            c. Pour épargner les étoiles de vos manipulations, sélectionnez-les
            comme indiqué plus haut dans cet article. Puis, sur votre calque «
            renforcé », supprimez les étoiles avec la touche « Suppr ».
            Attention tout de même, cette manipulation peut créer des artefacts
            graphiques, notamment en cas de différence d’intensité lumineuse
            entre le calque de base, et le calque « renforcé ».
          </li>
        </ul>
      </Section>
      <Section titre="Quelques extensions pour Photoshop">
        <p>
          Pour terminer, voici une petite sélection d’extensions (plugins) pour
          Photoshop dédiées à l’astrophotographie, testées et validées. Bien
          sûr, elles ne feront pas de miracles et transformeront pas une image
          pleine de défauts en une belle image. Il existe pour chacune de ces
          extensions une version pour Photoshop 64 bits (sauf les actions de
          Noël Carboni qui sont des scripts et qui marchent donc partout).
        </p>
        <ul>
          <li>
            Les indispensables{" "}
            <Lien urlExterne="https://www.prodigitalsoftware.com/AstronomyToolsActions.html">
              actions de Noël Carboni
            </Lien>{" "}
            (22 $). Les meilleures sont la réduction d’étoiles (« Make stars
            smaller »), le renforcement des couleurs des étoiles (« Increase
            star color »), et la réduction du bruit du fond de ciel (« Deep sky
            noise réduction »).{" "}
          </li>
          <li>
            <Lien urlExterne="https://www.prodigitalsoftware.com/StarSpikesPro4.html">
              Star Pikes Pro
            </Lien>
            , pour créer des aigrettes, mais aussi réparer des étoiles un peu
            déformées (utilisez des longueurs d’aigrettes à 0) et peu colorées
            (50 $).
          </li>
          <li>
            <Lien urlExterne="http://www.deepskycolors.com/archive/2010/04/26/hasta-La-Vista-Green.html">
              HLVG : Hasta La Vista Green !
            </Lien>{" "}
            Pour supprimer l’excès de vert de vos images.{" "}
          </li>
          <li>
            <Lien urlExterne="http://www.rc-astro.com/resources/GradientXTerminator">
              Gradient Xterminator
            </Lien>{" "}
            : comme son nom l’indique (50 $).{" "}
          </li>
          <li>
            <Lien urlExterne="http://dl-c.com/site/downloads/cm-apps.php">
              Color Mechanic Pro
            </Lien>{" "}
            : ce plugin méconnu et très puissant est capable de remplacer une
            couleur par une autre. Il n’est pas donné (70 $, 30 jours d’essai),
            mais est extrêmement puissant pour corriger une teinte « saumon » un
            peu trop tenace d’une image HαRVB. N’appliquez pas de corrections
            trop fortes, sous peine de faire apparaître des artefacts :{" "}
          </li>
        </ul>
      </Section>
    </LayoutTutorielFR>
  )
}
